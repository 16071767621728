import {
  getActiveWriters,
  getArticleSeries,
  getArticleTypes,
  getProfilesForArticlesPage,
} from "../../Api/Api";
import {
  ArticleSerie,
  ArticleType,
  City,
  Country,
  Images,
  Profile,
  Staff,
  State,
} from "../../Models/Models";
import React, { useEffect, useRef, useState } from "react";
import "./ArticlesForm.css";
import { postArticles, postArticleImages, getProfiles } from "../../Api/Api";

import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { AddAPhoto } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { Amplify, Storage } from "aws-amplify";
import awsconfig from "../../aws-exports";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import axios from "axios";
import locations from "../../Assets/csc.json";

Amplify.configure(awsconfig);

//////declarations

const defaultFormData = {
  articleID: 0,
  articleTypeID: 1,
  articleSerieID: 1,
  articleTypeName: "",
  serieName: "",
  title: "",
  descriptionResume: "",
  description: "",
  text: "",
  thumbnail_img: "",
  reelThumbnail: "",
  video: "",
  videoShort: "",
  videoReel: "",
  date: new Date(),
  city: "",
  featured: false,
  writerID: 1,
  writerName: "",
  active: true,
  paragraph1: "",
  paragraph2: "",
  paragraph3: "",
  paragraph4: "",
  paragraph5: "",
  paragraph6: "",
  paragraph7: "",
  paragraph8: "",
  paragraph9: "",
  paragraph10: "",
  profiles: [],
  images: [],
  countryCode: "US",
  countryName: "",
  cityCode: "",
  cityName: "",
  stateCode: "",
  stateName: "",
};

export default function ArticlesForm(props: {
  formData: any;
  updateFormData: (formData: any) => void;
}) {
  const [articleTypes, setArticleTypes] = useState<Array<ArticleType>>([]);
  const [articleSeries, setArticleSeries] = useState<Array<ArticleSerie>>([]);
  const [articleImages, setArticleImages] = useState<FileList>();
  const [profiles, setprofiles] = useState<Array<Profile>>([]);
  const [images, setImages] = useState<Array<Images>>([]);
  const [videoFile, setvideoFile] = useState<File | null>(null);
  const [videoFileShort, setvideoFileShort] = useState<File | null>(null);
  const [videoFileReel, setvideoFileReel] = useState<File | null>(null);

  const [writers, setWriters] = useState<Array<Staff>>([]);
  const inputFile = useRef(document.createElement("input"));
  const inputFile2 = useRef(document.createElement("input"));

  const [pict, setPict] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isHoveredPict, setIsHoeverdPict] = useState(false);
  const [isHoveredPictreelthumbnail, setisHoveredPictreelthumbnail] = useState(false);
  const [pictreel, setPictreel] = useState<File | null>(null);

  const queryClient = useQueryClient();

  const [countries, setCountries] = useState<Country[]>(locations as Country[]);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const path = "Articles/";

  function extractNameFromPath(path: string): string {
    // Split the path by the directory separator
    const parts = path.split("/"); // Assuming Unix-like paths

    // Get the last part which represents the file or directory name
    const name = parts[parts.length - 1];

    // Remove extension if it exists
    const nameWithoutExtension = name.split(".")[0];

    return nameWithoutExtension;
  }
  useEffect(() => {
    // This will log the updated articleImages after the state has been updated
    console.log("Updated articleImages:", articleImages);
  }, [articleImages]);

  useEffect(() => {
    getArticleTypes("asasa").then((resp) => {
      if (resp.data) setArticleTypes(resp.data);
    });

    getArticleSeries("asasa").then((resp) => {
      if (resp.data) setArticleSeries(resp.data);
    });
    getActiveWriters("asasa").then((resp) => {
      if (resp.data) setWriters(resp.data);
    });
    getProfilesForArticlesPage("asasa").then((resp) => {
      if (resp) setprofiles(resp);
    });
  }, []);

  useEffect(() => {
    // Fetch countries from Geonames API
    ////if formdata have data need to reload , states and cities.

    if (
      typeof props.formData.stateCode != "undefined" &&
      props.formData.stateCode
    ) {
      reloadStatesAndCities(props.formData.stateCode);
    }
    reloadCities(props.formData.countryCode);
  }, []);

  /////functionsssssssss
  const handleCountryInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    var country = countries.find((i) => i.name === value) ?? null;
    if (country) {
      reloadStatesAndCities(country.id.toString());
      console.log("the country is :" + country.name);
      props.updateFormData({
        ...props.formData,
        ["countryName"]: country.name,
        ["countryCode"]: country.iso2.toString(),
      });
    }
  };

  const handleStateInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    var state = states.find((i) => i.name === value) ?? null;

    if (state) {
      reloadCities(state.id.toString());
    }
  };

  const handleStateChange = (
    event: React.ChangeEvent<{}>,
    value: State | null
  ) => {
    setSelectedState(value);

    var id = value?.id;
    var name = value?.name;

    props.updateFormData({
      ...props.formData,
      ["stateCode"]: id?.toString(),
      ["stateName"]: name,
    });
    if (id) reloadCities(id.toString());
    else setCities([]);
  };

  const handleCityChange = (
    event: React.ChangeEvent<{}>,
    value: City | null
  ) => {
    setSelectedCity(value);

    var id = value?.id;
    var name = value?.name;

    props.updateFormData({
      ...props.formData,
      ["cityCode"]: id?.toString(),
      ["cityName"]: name,
    });
  };

  const reloadStatesAndCities = (value: string) => {
    const selectedCountry = countries.find(
      (country) => country.id.toString() === value
    );
    const statesData: State[] = [];

    selectedCountry?.states.forEach((state) => {
      statesData.push({
        id: state.id,
        name: state.name,
        cities: state.cities.map((city) => ({ id: city.id, name: city.name })),
      });
    });

    setStates(statesData);
  };

  const reloadCities = (value: string) => {
    const selectedState = states.find((state) => state.id.toString() === value);
    const citiesData: City[] = [];

    selectedState?.cities.forEach((city) => {
      citiesData.push({ id: city.id, name: city.name });
    });

    setCities(citiesData);
    console.log("Cities array:", cities);
  };

  const handleCountryChange = (
    event: React.ChangeEvent<{}>,
    value: Country | null
  ) => {
    if (value) {
      setSelectedCountry(value);

      var id = value.id.toString();
      var name = value.name;
      var countryCode = value.iso2.toString();
      console.log(name);
      props.updateFormData({
        ...props.formData,
        ["countryName"]: name,
        ["countryCode"]: countryCode,
      });

      console.log(props.formData);
      reloadStatesAndCities(id);
    } else {
      setSelectedCountry(null);
      setStates([]);
      setCities([]);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    props.updateFormData({ ...props.formData, [name]: value });
  };

  const handleChangeDate = (newValue: Dayjs | null) => {
    props.updateFormData({ ...props.formData, ["date"]: newValue });
  };
  const getFileName = (path: string) => {
    return path != null && path.replace(/^.*[\\\/]/, "");
  };

  const handleChangePict = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setPict(event.target.files[0]);
      props.updateFormData({
        ...props.formData,
        ["thumbnail_img"]: event.target.files[0].name,
      });
      console.log(props.formData);
    }
  };

  const handleChangePictReel = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setPictreel(event.target.files[0]);
      props.updateFormData({
        ...props.formData,
        ["reelThumbnail"]: event.target.files[0].name,
      });
      console.log(props.formData);
    }
  };

  const handleCheckbooks = (e: any) => {
    props.updateFormData({
      ...props.formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSaving(true);
    console.log(props.formData);

    postArticles(props.formData, "sss").then((resp: any) => {
      console.log(resp);
      props.updateFormData(defaultFormData);
      queryClient.refetchQueries(["Articles"]);
      setOpen(true);
      handleUpload(resp);
      handleUploadreelThumb(resp);
      handleAllImagesUpload(resp);
      setPict(null);
      ///upload the videos if needed
      if (videoFile != null) {
        var path = getVideoPath(videoFile, resp, 1);
        videoUpload(videoFile, 1, path);
      }
      if (videoFileShort != null) {
        var path = getVideoPath(videoFileShort, resp, 2);
        videoUpload(videoFileShort, 2, path);
      }
      if (videoFileReel != null) {
        var path = getVideoPath(videoFileReel, resp, 3);
        videoUpload(videoFileReel, 3, path);
      }
    });
    setIsSaving(false);
  };

  const handleInputImageClick = (e: any) => {
    if (inputFile.current) inputFile.current.click();
  };
  const handleInputImageClickReel = (e: any) => {
    if (inputFile2.current) inputFile2.current.click();
  };
  const handleAllImagesUpload = async (id: number) => {
    //will need to iterate on all images list.
    //upload image to AWS
    //add articleImage relation.
    const finalpath = path + id + "/";
    if (articleImages) {
      for (let i = 0; i < articleImages.length; i++) {
        try {
          console.log(finalpath + articleImages[i].name);
          await Storage.put(
            finalpath + articleImages[i].name,
            articleImages[i],
            {}
          );

          postArticleImages(id, articleImages[i].name, "ss");
        } catch (error) {
          console.log("Error uploading file: ", error);
        }
      }
    }
  };
  const handleUpload = async (id: number) => {
    const file = pict;
    const finalpath = path + id + "/";
    if (file)
      try {
        await Storage.put(finalpath + file.name, file, {});
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
  };

  const handleUploadreelThumb = async (id: number) => {
    const file = pictreel;
    const finalpath = path + id + "/";
    if (file)
      try {
        await Storage.put(finalpath + file.name, file, {});
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
  };
  const handleImageChange = (files: FileList) => {
    console.log("images adding now");
    console.log(files);
    setArticleImages(files);
    console.log(articleImages);
    // for (let i = 0; i < files.length; i++) {
    //   console.log(`File ${i + 1}: ${files[i].name}`);
    // }
  };

  const handleSelectProfileChange = (event: any, value: Profile[]) => {
    props.updateFormData({ ...props.formData, ["profiles"]: value });
    console.log(value);
  };

  const handleRemoveProfileItem = (value: number) => {
    setprofiles(profiles.filter((item) => item.profileID !== value));
  };

  /////video items
  const handleMainvideochange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      props.updateFormData({ ...props.formData, ["video"]: file.name });
      setvideoFile(file);
    }
  };

  const handleShortvideochange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      props.updateFormData({ ...props.formData, ["videoShort"]: file.name });
      setvideoFileShort(file);
    }
  };

  const handleReelvideochange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      props.updateFormData({ ...props.formData, ["videoReel"]: file.name });

      setvideoFileReel(file);
    }
  };

  function getVideoPath(file: File, Id: number, type: number): string {
    /// create the filepath based on the file

    var name = file.name;
    var videoType = "Video";
    if (type == 2) videoType = "VideoShort";
    else if (type == 3) videoType = "VideoReel";
    var filepath = path + Id + "/" + videoType + "/" + name;

    // Example processing: concatenate file content with numbers

    return filepath;
  }

  const videoUpload = async (file: File, type: number, path: string) => {
    console.log(path);
    if (file)
      try {
        console.log("uploading");
        const options = {
          contentType: "video/" + file.name.split(".").pop(), // Extract extension
        };

        // Add progress tracking using an await/async approach
        const result = await Storage.put(path, file, {
          bucket: "api.thelocalproject",
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
        });
        console.log("Video uploaded successfully:", result);
      } catch (error) {
        console.error("Error uploading video:", error);
        // Handle errors appropriately
      }
  };
  ///
  /////////////////
  return (
    <div className="form" id="form">
      {isSaving && <CircularProgress size="4rem" className="spinner" />}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Article Updated!!
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <div className="d-flex d-flex-row flex-fill">
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              placeholder="Select Type"
              options={articleTypes}
              openOnFocus
              value={
                articleTypes.find(
                  (i) => i.articleTypeID === props.formData.articleTypeID
                ) ?? null
              }
              inputValue={
                articleTypes.find(
                  (i) => i.articleTypeID === props.formData.articleTypeID
                )?.articleTypeName ?? ""
              }
              getOptionLabel={(option) => option.articleTypeName}
              onChange={(event, newValue) => {
                props.updateFormData({
                  ...props.formData,
                  ["articleTypeID"]: newValue?.articleTypeID ?? 0,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Type" name="type" />
              )}
              fullWidth
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              placeholder="Select Serie"
              options={articleSeries}
              openOnFocus
              value={
                articleSeries.find(
                  (i) => i.articleSerieID === props.formData.articleSerieID
                ) ?? null
              }
              inputValue={
                articleSeries.find(
                  (i) => i.articleSerieID === props.formData.articleSerieID
                )?.serieName ?? ""
              }
              getOptionLabel={(option) => option.serieName}
              onChange={(event, newValue) => {
                props.updateFormData({
                  ...props.formData,
                  ["articleSerieID"]: newValue?.articleSerieID ?? 0,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Serie" name="Serie" />
              )}
              fullWidth
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            {/* <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              placeholder="Select Writer"
              options={writers}
              openOnFocus
              value={
                writers.find((i) => i.staffID === props.formData.writerID) ??
                null
              }
              inputValue={
                writers.find((i) => i.staffID === props.formData.writerID)
                  ?.firstName ?? ""
              }
              getOptionLabel={(option) => option.firstName}
              onChange={(event, newValue) => {
                props.updateFormData({
                  ...props.formData,
                  ["writerID"]: newValue?.staffID ?? 0,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Writer" name="writer" />
              )}
              fullWidth
            /> */}
          </div>
        </div>
        <div className=" d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Autocomplete
              size="small"
              fullWidth
              openOnFocus
              onChange={handleCountryChange}
              onInputChange={handleCountryInputChange}
              options={countries}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Article's Based Country"
                  name="country"
                />
              )}
              getOptionLabel={(option) => option.name}
              value={
                countries.find(
                  (i) => i.iso2.toString() === props.formData.countryCode
                ) ?? null
              }
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Autocomplete
              size="small"
              fullWidth
              disablePortal
              openOnFocus
              onChange={handleStateChange}
              onInputChange={handleStateInputChange}
              options={states}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Article's Based State" />
              )}
              disabled={!props.formData.countryCode}
              value={
                states.find(
                  (i) => i.id.toString() === props.formData.stateCode
                ) ?? null
              }
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Autocomplete
              size="small"
              fullWidth
              disablePortal
              openOnFocus
              onChange={handleCityChange}
              options={cities}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Article's Based City" />
              )}
              disabled={!props.formData.countryCode}
              value={
                cities.find(
                  (i) => i.id.toString() === props.formData.cityCode
                ) ?? null
              }
            />{" "}
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm"> </div>
        </div>
        <div className=" d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="title"
              label="Title"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              placeholder="Enter Title"
              required
              value={props.formData.title}
              size="small"
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Button
              variant="contained"
              component="label"
              style={{ textTransform: "none" }}
            >
              <input
                hidden
                name="video"
                placeholder="Upload a video"
                type="file"
                onChange={handleMainvideochange}
              />
              {!props.formData.video?.length && (
                <span> Choose Video to upload</span>
              )}
              {!!props.formData.video?.length && (
                <span> {extractNameFromPath(props.formData.video)}</span>
              )}
            </Button>
          </div>

          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Button
              variant="contained"
              component="label"
              style={{ textTransform: "none" }}
            >
              <input
                hidden
                name="video"
                placeholder="Upload a video"
                type="file"
                onChange={handleShortvideochange}
              />
              {!props.formData.videoShort?.length && (
                <span> Choose ShortVideo</span>
              )}
              {!!props.formData.videoShort?.length && (
                <span> {extractNameFromPath(props.formData.videoShort)}</span>
              )}
            </Button>
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <Button
              variant="contained"
              component="label"
              style={{ textTransform: "none" }}
            >
              <input
                hidden
                name="video"
                placeholder="Upload a video"
                type="file"
                onChange={handleReelvideochange}
              />
              {!props.formData.videoReel?.length && <span> Choose Reel</span>}
              {!!props.formData.videoReel?.length && (
                <span> {extractNameFromPath(props.formData.videoReel)}</span>
              )}
            </Button>
          </div>
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <TextField
              id="outlined-multiline-flexible"
              label="DescriptionResume"
              name="descriptionResume"
              placeholder="Enter DescriptionResume"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              fullWidth
              required
              size="small"
              minRows={1}
              value={props.formData.descriptionResume}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              name="description"
              placeholder="Enter Description"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              required
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.description}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex d-flex-colum flex-fill flexColunm">
            <div className="d-flex d-flex-row flex-fill ">
              <div className="d-flex d-flex-colum  flexColunm">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="MM/DD/YYYY"
                    value={props.formData.date}
                    onChange={handleChangeDate}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className=" d-flex d-flex-row flex-fill align-items-start">
              <div className="d-flex d-flex-colum flex-fill flexColunm">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="active"
                      value={props.formData.active}
                      checked={props.formData.active}
                      onChange={(e) => handleCheckbooks(e)}
                    />
                  }
                  label="Active"
                />
              </div>
              <div className="d-flex d-flex-colum flex-fill flexColunm">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="featured"
                      value={props.formData.featured}
                      checked={props.formData.featured}
                      onChange={(e) => handleCheckbooks(e)}
                    />
                  }
                  label="Featured"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph1"
              name="paragraph1"
              placeholder="Enter Paragraph1"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph1}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph2"
              name="paragraph2"
              placeholder="Enter Paragraph2"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph2}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph3"
              name="paragraph3"
              placeholder="Enter Paragraph3"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph3}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph4"
              name="paragraph4"
              placeholder="Enter Paragraph4"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph4}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph5"
              name="paragraph5"
              placeholder="Enter Paragraph5"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph5}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph6"
              name="paragraph6"
              placeholder="Enter Paragraph6"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph6}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph7"
              name="paragraph7"
              placeholder="Enter Paragraph7"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph7}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph8"
              name="paragraph8"
              placeholder="Enter Paragraph8"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph8}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow">
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph9"
              name="paragraph9"
              placeholder="Enter Paragraph9"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph9}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex d-flex-colum flex-fill flexColunm ">
            <TextField
              id="outlined-multiline-flexible"
              label="Paragraph10"
              name="paragraph10"
              placeholder="Enter Paragraph10"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              size="small"
              minRows={1}
              fullWidth
              value={props.formData.paragraph10}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow ">
          <div className="col col-lg-6">
            <Autocomplete
              disablePortal
              multiple
              size="small"
              id="combo-box-demo"
              placeholder="Select Profiles"
              options={profiles}
              openOnFocus
              value={props.formData.profiles}
              // value={profiles.filter((el) => props.formData.profiles.includes(el.profileID))}
              // value={
              //   profiles.find(
              //     (i) => i.profileID === props.formData.articleTypeID
              //   ) ?? null
              // }
              // inputValue={
              //   articleTypes.find(
              //     (i) => i.articleTypeID === props.formData.articleTypeID
              //   )?.articleTypeName ?? ""
              // }
              getOptionLabel={(option) => option.displayName}
              onChange={handleSelectProfileChange}
              renderInput={(params) => (
                <TextField {...params} label="Profiles" name="profiles" />
              )}
              // filterOptions={(options, params) => {
              //   const filtered = options.filter(
              //     (option) =>
              //       option.displayName
              //         .toLowerCase()
              //         .indexOf(params.inputValue.toLowerCase()) !== -1
              //   );
              //   return filtered;
              // }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.profileID}>
                    <span>{option.displayName}</span>
                  </li>
                );
              }}
              // fullWidth
            />
          </div>
          <div className="col col-lg-6">
            <TextField
              id="input-with-icon-textfield"
              label="Thumbnail"
              size="small"
              name="thumbnail_img"
              value={getFileName(props.formData.thumbnail_img)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddAPhoto />
                  </InputAdornment>
                ),
              }}
              onClick={handleInputImageClick}
              disabled
              onMouseEnter={() => setIsHoeverdPict(true)}
              onMouseLeave={() => setIsHoeverdPict(false)}
              variant="outlined"
              fullWidth
            ></TextField>
            <input
              hidden
              ref={inputFile}
              accept="image/*"
              multiple
              type="file"
              onChange={handleChangePict}
            />
          </div>
          {isHoveredPict && (
            <dialog
              className="dialog"
              style={{ position: "absolute", zIndex: 9999 }}
              open
            >
              <img
                className="image"
                src={
                  props.formData.thumbnail_img != ""
                    ? props.formData.thumbnail_img
                    : pict != null
                    ? URL.createObjectURL(pict)
                    : ""
                }
                alt="no image"
              />
            </dialog>
          )}
        </div>
        <div className="d-flex d-flex-row flex-fill flexformrow">
        <div className="col col-lg-6">
            <TextField
              id="textfieldREEL"
              label="Reel thumbnail"
              size="small"
              name="reelthumbnail"
              value={getFileName(props.formData.reelThumbnail)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddAPhoto />
                  </InputAdornment>
                ),
              }}
              onClick={handleInputImageClickReel}
              disabled
              onMouseEnter={() => setisHoveredPictreelthumbnail(true)}
              onMouseLeave={() => setisHoveredPictreelthumbnail(false)}
              variant="outlined"
              fullWidth
            ></TextField>
            <input
              hidden
              ref={inputFile2}
              accept="image/*"
              multiple
              type="file"
              onChange={handleChangePictReel}
            />
          </div>
          {isHoveredPictreelthumbnail && (
            <dialog
              className="dialog"
              style={{ position: "absolute", zIndex: 9999 }}
              open
            >
              <img
                className="image"
                src={
                  props.formData.reelThumbnail != ""
                    ? props.formData.reelThumbnail
                    : pictreel != null
                    ? URL.createObjectURL(pictreel)
                    : ""
                }
                alt="no image"
              />
            </dialog>
          )}
        </div>

        <div className="d-flex d-flex-row flex-fill flexformrow ">
          <ImageUploader
            onChange={handleImageChange}
            initialImages={props.formData.images}
          />
        </div>
        <div className="d-flex flex-row-reverse">
          <Button
            type="submit"
            sx={{ width: 200, padding: 0, margin: 2 }}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
