import { CognitoUserPool } from "amazon-cognito-identity-js";


// const poolData = {
//     UserPoolId: "us-east-1_EuUclH4aQ",
//     ClientId: "2377hm84d4bomhfs2bf6fdri4c",
// }

const poolData = {
    UserPoolId: "us-east-2_lKtSsV5dY",
    ClientId: "5glmtb920im4f5c2q0nccie2j",
}

export default new CognitoUserPool(poolData);