import axios from "axios";
import { profile } from "console";
import { Article, Profile, Staff } from "../Models/Models";
// import https from "https";
const tlpKey =
  "Owu1gd8psUSMUqNwWh8z1UToomT8xyHqtfFQXRUx9dp6kkDlbbODPWU3xx2dsBsYMZWOAFdBUcknrkKiynmlaHoMT4o8z6TCqTOD3t1AA5r4W3tqV7JnooYhWFNdhdZ8";

const environment = `https://api.thearchitecturalauthority.com/api`;
// const environment = `http://localhost:38008/api`;

export default async function checkAuth(jwetToken: string) {
  return await axios.get(`${environment}/api/user`, {
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      Authorization: `Bearer ${jwetToken}`,
      TLPKey: tlpKey,
    },
  });
}

export function getProfileTypes(jwtToken: string) {
  return axios.get(`${environment}/Profiles/GetProfileTypes`, {
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      TLPKey: tlpKey,
    },
  });
}

export function getProfileTiers(jwtToken: string) {
  return axios.get(`${environment}/Profiles/GetTiers`, {
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      TLPKey: tlpKey,
    },
  });
}

export function getProfiles(jwtToken: string, callback?: any) {
  return axios
    .get(`${environment}/Profiles`, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        TLPKey: tlpKey,
      },
    })
    .then((response) => {
      if (callback) callback(response.data);
      return response.data;
    });
}

export function getProfilesForArticlesPage(jwtToken: string, callback?: any) {
  return axios
    .get(`${environment}/Profiles/GetProfilesforArticlePage`, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        TLPKey: tlpKey,
      },
    })
    .then((response) => {
      if (callback) callback(response.data);
      return response.data;
    });
}

export function postProfile(profile: Profile, jwtToken: string) {
  return axios
    .post(`${environment}/Profiles`, profile, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "content-type": "application/json",
        TLPKey: tlpKey,
      },
    })
    .then((response) => response.data);
}

export function deleteProfile(jwtToken: string, profileID: number) {
  return axios.delete(`${environment}/Profiles/`, {
    params: { profileId: profileID },
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      TLPKey: tlpKey,
      Authorization: `Bearer ${jwtToken}`,
    },
  });
}

///////////////////////////WRITERS SECTION///////////////////

export function getWriters(jwtToken: string, callback?: any) {
  return axios
    .get(`${environment}/Writers`, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,

        TLPKey: tlpKey,
      },
    })
    .then((response) => {
      if (callback) callback(response.data);
      return response.data;
    });
}

export function getStaffTypes(jwtToken: string) {
  return axios.get(`${environment}/Writers/GetStaffTypes`, {
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      TLPKey: tlpKey,
    },
  });
}

export function postWriter(writer: Staff, jwtToken: string) {
  return axios
    .post(`${environment}/Writers`, writer, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "content-type": "application/json",
        TLPKey: tlpKey,
      },
    })
    .then((response) => {
      return response.data;
    });
}

/////////ARTICLES////////////////

export function getActiveWriters(jwtToken: string) {
  return axios.get(`${environment}/Writers/GetActiveWriters`, {
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      TLPKey: tlpKey,
    },
  });
}

export function getArticleTypes(jwtToken: string) {
  return axios.get(`${environment}/Articles/getArticleType`, {
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      TLPKey: tlpKey,
    },
  });
}

export function getArticleSeries(jwtToken: string) {
  return axios.get(`${environment}/Articles/getArticleSeries`, {
    // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      TLPKey: tlpKey,
    },
  });
}

export function postArticles(article: Article, jwtToken: string) {
  return axios
    .post(`${environment}/Articles`, article, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        "content-type": "application/json",
        TLPKey: tlpKey,
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function getArticles(jwtToken: string, callback?: any) {
  return axios
    .get(`${environment}/Articles/getArticles`, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        TLPKey: tlpKey,
      },
    })
    .then((response) => {
      if (callback) callback(response.data);
      return response.data;
    });
}

export function getArticlebyID(
  articleId: number,
  jwtToken: string,
  callback?: any
) {
  return axios
    .get(`${environment}/Articles/GetArticlebyID/` + articleId, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        TLPKey: tlpKey,
      },
    })
    .then((response) => {
      if (callback) callback(response.data);
      return response.data;
    });
}

export function postArticleImages(
  articleID: number,
  imageURL: string,
  jwtToken: string
) {
  return axios
    .post(
      `${environment}/Articles/AddArticleImage/` + articleID + "/" + imageURL,
      {},
      {
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "content-type": "application/json",
          TLPKey: tlpKey,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function deleteArticleImage(
  imageID: number,
  jwtToken: string,
  callback?: any
) {
  return axios
    .get(`${environment}/Articles/DeleteArticleImage/` + imageID, {
      // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        tlpkey: tlpKey,
      },
    })
    .then((response) => {
      if (callback) callback(response.data);
      return response.data;
    });
}

export function getVideoPlayer(jwtToken: string, callback?: any) {
  return axios
    .get(
      `https://3dzmz2bvw2.execute-api.us-east-1.amazonaws.com/Dev/api/videoplayer`,
      {
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          Api_Key:
            "c2lxKkfOxMtWw1nTsNZLbOoEMoH5q72gNqfnDxWYpuRTE6ae8zGNQ5A1uULMjG6q",
        },
      }
    )
    .then((response) => {
      if (callback) callback(response.data);
      return response.data;
    });
}
