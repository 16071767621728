import * as React from "react";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import loginImg from "../../Assets/Logotype-Black.png";
import useAuth from "../../Helpers/useAuth";
import Copyright from "../Footer/Copyright";
import "./Login.css";

const theme = createTheme();

export default function Login() {
  const { isProcessing, isAuthenticated, isError, authenticate } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isCopyrightVisible, setIsCopyrightVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var emails =
      "hello@thearchitecturalauthority.com , promocionhabana@gmail.com ,bea@thearchitecturalauthority.com";

    if (containsValue(emails, email)) {
      authenticate(email, password, {
        onSuccess: () => {
          navigate("/Home", { state: { authenticated: true } });
          // else
          // alert("you are not authorized")
        },
        rememberMe: rememberMe,
      });
    } else alert("you are not authorized");
  };

  function containsValue(csvString: string, value: string): boolean {
    const valuesArray = csvString.split(",").map((item) => item.trim());
    return valuesArray.includes(value);
  }
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsFormVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopyrightVisible(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`loginImage ${isFormVisible ? "visible" : ""}`}>
          <img
            src={loginImg}
            width="350"
            style={{ position: "relative" }}
            alt="login"
          />
        </div>
        <form
          onSubmit={handleSubmit}
          noValidate
          className={`loginForm ${isFormVisible ? "visible" : ""}`}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                name="rememberMe"
                color="primary"
              />
            }
            label="Remember Me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </form>
        <div className={`copyright ${isCopyrightVisible ? "visible" : ""}`}>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </div>
      </Container>
    </ThemeProvider>
  );
}
