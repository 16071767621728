import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Input,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { getStaffTypes, postWriter } from "../../Api/Api";
import { AddAPhoto } from "@mui/icons-material";
import "./Writersform.css";
import { Amplify, Storage } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { StaffTypes } from "../../Models/Models";
Amplify.configure(awsconfig);

const bucketName = "api.thelocalproject";
const region = "us-east-1";
const path = "Writers/";
const AWSPath = "https://s3.amazonaws.com/api.thelocalproject/public/Writers/";

export default function WritersForm(props: {
  formData: any;
  updateFormData: (formData: any) => void;
}) {
  ///declarations
  const queryClient = useQueryClient();
  const [isHoveredPict, setIsHoeverdPict] = useState(false);
  const inputFile = useRef(document.createElement("input"));
  const [isSaving, setIsSaving] = useState(false);
  const [staffTypes, setStaffTypes] = useState<Array<StaffTypes>>([]);

  useEffect(() => {
    getStaffTypes("asasa").then((resp) => {
      if (resp.data) setStaffTypes(resp.data);
    });
  }, []);

  const defaultFormData = {
    staffID: 0,
    firstName: "",
    lastName: "",
    email: "",
    webSite: "",
    country: "",
    city: "",
    thumbnail_img: "",
    active: true,
    staffTypes: [],
  };
  const [open, setOpen] = React.useState(false);
  const [pict, setPict] = useState<File | null>(null);
  ////functions
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSaving(true);

    postWriter(props.formData, "sss").then((resp) => {
      console.log(resp);
      props.updateFormData(defaultFormData);
      queryClient.refetchQueries(["Writers"]);
      setOpen(true);
      handleUpload(resp);
      setPict(null);
    });
    setIsSaving(false);
  };

  const handleUpload = async (id: number) => {
    const file = pict;
    const finalpath = path + id + "/";
    if (file)
      try {
        await Storage.put(finalpath + file.name, file, {});
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    props.updateFormData({ ...props.formData, [name]: value });
  };

  const handleChangePict = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setPict(event.target.files[0]);
      props.updateFormData({
        ...props.formData,
        ["thumbnail_img"]: event.target.files[0].name,
      });
      console.log(props.formData);
    }
  };

  const handleCheckbooks = (e: any) => {
    props.updateFormData({
      ...props.formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleInputImageClick = (e: any) => {
    if (inputFile.current) inputFile.current.click();
  };

  const getFileName = (path: string) => {
    return path.replace(/^.*[\\\/]/, "");
  };

  const handleStaffTypesChange = (event: any, value: number[]) => {
    props.updateFormData({ ...props.formData, ["staffTypes"]: value });
    console.log(value);
  };
  /////////////////
  return (
    <div className="form" id="form">
      {isSaving && <CircularProgress size="4rem" className="spinner" />}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Writer Updated!!
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <div className="row formrow ">
          <div className="col col-lg-4">
            <Autocomplete
              disablePortal
              multiple
              size="small"
              id="combo-box-demo"
              placeholder="SelectTye"
              options={staffTypes}
              openOnFocus
              value={props.formData.staffTypes}
              getOptionLabel={(option) => option.staffType}
              onChange={handleStaffTypesChange}
              renderInput={(params) => (
                <TextField {...params} label="Type" name="type" />
              )}
              fullWidth
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.staffTypeID}>
                    <span>{option.staffType}</span>
                  </li>
                );
              }}
            />
          </div>

          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="firstName"
              label="FirstName"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              placeholder="Enter FirstName"
              value={props.formData.firstName}
              required
              size="small"
            />
          </div>
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="lastName"
              label="LastName"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              placeholder="Enter LastName"
              required
              value={props.formData.lastName}
              size="small"
            />
          </div>
        </div>

        <div className="row formrow ">
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="country"
              label="Country"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              placeholder="Enter Country"
              value={props.formData.country}
              required
              size="small"
            />
          </div>
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="city"
              label="City"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              placeholder="Enter City"
              required
              value={props.formData.city}
              size="small"
            />
          </div>
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="email"
              label="Email"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Email"
              variant="outlined"
              onChange={handleChange}
              value={props.formData.email}
              size="small"
            />
          </div>
        </div>
        <div className="row formrow">
          <div className="col col-lg-6">
            <TextField
              id="input-with-icon-textfield"
              label="Image"
              size="small"
              name="thumbnail_img"
              value={getFileName(props.formData.thumbnail_img)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddAPhoto />
                  </InputAdornment>
                ),
              }}
              onClick={handleInputImageClick}
              disabled
              onMouseEnter={() => setIsHoeverdPict(true)}
              onMouseLeave={() => setIsHoeverdPict(false)}
              variant="outlined"
              fullWidth
            ></TextField>
            <input
              hidden
              ref={inputFile}
              accept="image/*"
              multiple
              type="file"
              onChange={handleChangePict}
            />

            {isHoveredPict && (
              <dialog
                className="dialog"
                style={{ position: "absolute", zIndex: 9999 }}
                open
              >
                <img
                  className="image"
                  src={
                    props.formData.thumbnail_img != ""
                      ? props.formData.thumbnail_img
                      : pict != null
                      ? URL.createObjectURL(pict)
                      : ""
                  }
                  alt="no image"
                />
              </dialog>
            )}
          </div>
          <div className=" col col-lg-6">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="webSite"
              label="Web"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Website"
              variant="outlined"
              onChange={handleChange}
              required
              value={props.formData.webSite}
              size="small"
            />
          </div>

          <div className="col  d-flex flex-row-reverse">
            <FormControlLabel
              control={
                <Checkbox
                  name="active"
                  value={props.formData.active}
                  checked={props.formData.active}
                  onChange={(e) => handleCheckbooks(e)}
                />
              }
              label="Active"
            />
          </div>
        </div>

        <div className="d-flex flex-row-reverse">
          <Button
            type="submit"
            sx={{ width: 200, padding: 1, margin: 2 }}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
