import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../Helpers/useAuth";
import React, { useState } from "react";
import { checkAuth } from "../../Helpers/Helpers";
import { useCookies } from "react-cookie";
import Navbar from "../LeftMenu/navbar";
import Magazine from "../Magazine/Magazine";
import Photos from "../Photos/Photos";
import Profiles from "../Profiles/Profiles"
import "./Home.css";
import Amplify from 'aws-amplify';
import awsExports from '../../aws-exports';
import Writers from "../Writers/Writers";
import Articles from "../Articles/Articles";



export default function Home() {
  const { isAuthenticated, cognitoUser, data } = useAuth();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { authenticated } = !!state ? state : false;

  const [cookies, setCookie] = useCookies(["userId", "userIdJWETToken"]);
  const jwetToken = cookies["userIdJWETToken"];
  const [activePage, setActivePage] = React.useState("magazine");

  function renderSwitch(param: any) {
    switch (param) {
      case "magazine":
        return <Magazine/>;
       case "photos" :
        return <Photos/>;
       case "profiles" :
        return <Profiles/>
        case 'writers' :
          return <Writers/>
          case 'articles' :
          return <Articles/>
    }
  }

  if (!authenticated) {
    return <Navigate replace to="/login" />;
  } else
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="sidebar position-fixed col-2 sticky-top" style={{ padding: "0px" }}>
            <Navbar activePage={activePage} setActivePage={setActivePage} />
          </div>
          <div className=" col-10 bodycontent offset-2">
            {renderSwitch(activePage )} 
          </div>
        </div>
      </div>
    );
}
