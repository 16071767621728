import { useState } from "react";
import WritersForm from "../../Forms/Writers/WritersForm";
import WritersTable from "../../Tables/WritersTable";
import "./writers.css";

export default function Writers() {
  const defaultFormData = {
    staffID: 0,
    firstName: "",
    lastName: "",
    email: "",
    webSite: "",
    country: "",
    city: "",
    thumbnail_img: "",
    active: true,
    parragraph1: "",
    parragraph2: "",
    parragraph3: "",
    parragraph4: "",
    parragraph5: "",
    parragraph6: "",
    parragraph7: "",
    parragraph8: "",
    parragraph9: "",
    parragraph10: "",
    staffTypes: [],
  };

  const [formData, setFormData] = useState(defaultFormData);

  const updateFormData = (formData: any) => {
    setFormData(formData);
    console.log(formData);
  };

  return (
    <div>
      <div className="header">Staff administration page</div>

      <div className="formContainer" id="form">
        <WritersForm formData={formData} updateFormData={updateFormData} />
      </div>
      <br />
      <div id="table"></div>
      <WritersTable updateFormData={updateFormData} />
    </div>
  );
}
