import "./Profiles.css";
import Profilesform from "../../Forms/Profiles/Profilesform";
import ProfilesTable from "../../Tables/ProfileTable";
import { useState } from "react";

export default function Profiles() {
  const defaultFormData = {
    profileID: 0,
    client_Name: "",
    client_LastName: "",
    companyName: "",
    type: 1,
    typeDesc: "",
    thumbnail_img: "",
    cover_img: "",
    descriptionResume: "",
    phone: "",
    email: "",
    website: "",
    country: "",
    text: "",
    featured: false,
    city: "",
    active: true,
    showThumbnail: true,
    userAdded: "",
    dateAdded: "",
    staff: false,
    profTypes: [],
    countryCode: "US",
    countryName: "",
    cityCode: "",
    cityName: "",
    stateCode: "",
    stateName: "",
    tierId: 1,
    tierName: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  const updateFormData = (formData: any) => {
    console.log("from update  ", formData);
    setFormData(formData);
  };

  return (
    <div>
      <div className="headertitle">Add/Modify</div>
      <div className="formContainer" id="form">
        <Profilesform formData={formData} updateFormData={updateFormData} />
      </div>
      <br />
      <div id="table"></div>
      <ProfilesTable updateFormData={updateFormData} />
    </div>
  );
}
