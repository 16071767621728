import React, { useEffect, useState } from 'react';
import { deleteArticleImage } from '../../Api/Api';
import { Images } from '../../Models/Models';
import './ImageUploader.css';

type ImageUploaderProps = {
  onChange: (files: FileList) => void;
  initialImages?: Images[];
};

type ImagePreviewProps = {
  image: Images;
  onDelete: () => void;
};

const ImagePreview = ({ image, onDelete }: ImagePreviewProps) => {
  
  return (    
    <div className="image-preview">
      <img src={image.imageURL} alt="" />
      <button className="delete-button" onClick={onDelete}>
        &#x2715;
      </button>
    </div>
  );
};

async function createFile(url: string){
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: 'image/jpeg'
  };
  let file = new File([data], getFileName(url), metadata);
  return file;
}

const getFileName = (path: string) => {
  return path.replace(/^.*[\\\/]/, "");
};

const ImageUploader = ({ onChange, initialImages }: ImageUploaderProps) => {
  const [previewImages, setPreviewImages] = useState<Images[]>([]);

  useEffect(() => {
    if (initialImages) {
      setPreviewImages(initialImages);
      //create images based on initialimages
     
    }
  }, [initialImages]);

  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log(files)
    if (files) {
      onChange(files);
      const urls = [];
      const newImages: Images[] = [];
      const newOrder: number[] = [];
      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        urls.push({imageID: i, imageURL: url});
        const imageID = i; // or use a unique identifier for imageID
        newImages.push({ imageID, imageURL: url });
        newOrder.push(imageID);
      }
      setPreviewImages([...previewImages, ...newImages]);;
    }
  };

  const handleDelete = (imageID: number) => {
    const images = [...previewImages];
    const index = images.findIndex((image) => image.imageID === imageID);
    if (index >= 0) {
      images.splice(index, 1);
      setPreviewImages(images);
      deleteArticleImage(imageID,'token')
    }
  };

  return (
    <div className="image-uploader">
      <label className="image-uploader-label">
        Select Images
        <input type="file" name="images" multiple onChange={handleFileChange} />
      </label>
      <div className="image-uploader-preview">
        {previewImages.map((image) => (
          <ImagePreview key={image.imageID} image={image} onDelete={() => handleDelete(image.imageID)} />
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
