import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Profile } from "../Models/Models";
import { useEffect, useState } from "react";
import { deleteProfile, getProfiles } from "../Api/Api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Checkbox,
  darken,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  lighten,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import { red } from "@mui/material/colors";
import "./style.css";

export default function ProfileTable(props: {
  updateFormData: (formData: any) => void;
}) {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (searchQuery === "") setFilteredData(data);
  });

  const fetchProfiles = async (): Promise<Profile[]> => {
    return getProfiles("asasa").then((resp) => {
      if (resp.data) {
        console.log(resp.data);
        setProfiles(resp.data);
        setFilteredData(resp.data);
        return resp.data;
      }
    });
  };

  const { data, status, refetch } = useQuery<Array<Profile>>(["Profiles"], () =>
    getProfiles("asasa")
  );
  const [profiles, setProfiles] = useState<Array<Profile>>([]);
  const [open, setDialogOpen] = useState(false);
  const [clickedRow, setClickedRow] = React.useState(null);

  const onEditClick = (e: any, row: any) => {
    e.stopPropagation();
    setClickedRow(row);
    // console.log(row);
    props.updateFormData(row);
  };

  const deleteRow = () => {
    if (clickedRow) {
      console.log(clickedRow["profileID"]);
      deleteProfile("ksks", clickedRow["profileID"]);
    }
    queryClient.refetchQueries(["Profiles"]);

    return true;
  };

  const columns = [
    // { field: 'profileID', headerName: '', width: 50, renderCell: (params:any) => {
    //   return (
    //     <IconButton aria-label='delete' color='error'
    //     onClick={(e) => onDeleteClick(e, params.row)}
    //     >
    //       <DeleteIcon  />
    //     </IconButton>
    //   );
    // } },
    {
      field: "TypeDesc",
      headerName: "",
      width: 50,
      renderCell: (params: any) => {
        return (
          <IconButton
            aria-label="Edit"
            color="primary"
            onClick={(e) => onEditClick(e, params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    { field: "profileID", headerName: "ID", width: 50 },
    { field: "client_Name", headerName: "First name", flex: 1 },
    { field: "client_LastName", headerName: "Last name", flex: 1 },
    { field: "companyName", headerName: "Company", flex: 1 },
    // {field: 'typeDesc', headerName: 'Type',flex :1},
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "website", headerName: "Web", flex: 1 },
    { field: "countryName", headerName: "Country", flex: 1 },
    { field: "cityName", headerName: "City", flex: 1 },
    {
      field: "featured",
      headerName: "Featured",
      flex: 1,
      renderCell: (params: any) => {
        return <Checkbox checked={params.row.featured}></Checkbox>;
      },
    },
    {
      field: "staff",
      headerName: "Staff",
      flex: 1,
      renderCell: (params: any) => {
        return <Checkbox checked={params.row.staff}></Checkbox>;
      },
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setFilteredData(
      data &&
        data.filter((d) =>
          Object.values(d).some(
            (value) =>
              value !== null &&
              value.toString().toLowerCase().includes(query.toLowerCase())
          )
        )
    );
  };

  return (
    <div style={{ height: 800, width: "100%" }}>
      <input
        className="searchInput"
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <DataGrid
        rows={filteredData ?? []}
        columns={columns}
        // pageSize={10}
        autoPageSize
        getRowId={(row) => row.profileID}
        //  rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        rowHeight={25}
        sx={{
          boxShadow: 2,
          border: 2,

          "& .profile--false": {
            bgcolor: red,
          },
        }}
        getRowClassName={(params) => `profile--${params.row.active}`}
      />

      <Dialog open={open} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Do you want to delete this profile?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => deleteRow() && setDialogOpen(false)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
