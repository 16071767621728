import { useState } from "react";
import ArticlesForm from "../../Forms/Articles/ArticlesForm";
import ArticlesTable from "../../Tables/ArticlesTable";

export default function Articles() {
  const defaultFormData = {
    articleID: 0,
    articleTypeID: 1,
    articleSerieID: 1,
    articleTypeName: "",
    serieName: "",
    title: "",
    descriptionResume: "",
    description: "",
    text: "",
    thumbnail_img: "",
    reelThumbnail: "",
    video: "",
    videoShort: "",
    videoReel: "",
    date: new Date(),
    city: "",
    featured: false,
    writerID: 1,
    writerName: "",
    active: true,
    paragraph1: "",
    paragraph2: "",
    paragraph3: "",
    paragraph4: "",
    paragraph5: "",
    paragraph6: "",
    paragraph7: "",
    paragraph8: "",
    paragraph9: "",
    paragraph10: "",
    profiles: [],
    images: [],
    countryCode: "US",
    countryName: "",
    cityCode: "",
    cityName: "",
    stateCode: "",
    stateName: "",
  };

  const [formData, setFormData] = useState(defaultFormData);

  const updateFormData = (formData: any) => {
    setFormData(formData);
  };
  return (
    <div>
      <div className="header">Articles administration page</div>

      <div className="formContainer" id="form">
        <ArticlesForm formData={formData} updateFormData={updateFormData} />
      </div>
      <br />
      <div id="table"></div>
      {/* <WritersTable  updateFormData = {updateFormData}/> */}
      <ArticlesTable updateFormData={updateFormData}></ArticlesTable>
    </div>
  );
}
