import "./Profilesform";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  getProfileTiers,
  getProfileTypes,
  getVideoPlayer,
  postProfile,
} from "../../Api/Api";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { City, State, Country, ProfileType, Tier } from "../../Models/Models";
import "./profilesform.css";
import { Amplify, Storage } from "aws-amplify";
import awsExports from "../../aws-exports";
import {
  AddAPhoto,
  CatchingPokemonSharp,
  TireRepair,
} from "@mui/icons-material";
import Dropdown from "../country";
import locations from "../../Assets/csc.json";

Amplify.configure(awsExports);

const bucketName = "api.thelocalproject";
const region = "us-east-1";
const path = "Profiles/";

Storage.configure({
  region: region,
});

export default function Profilesform(props: {
  formData: any;
  updateFormData: (formData: any) => void;
}) {
  ////////////DECLARATIONS
  const defaultFormData = {
    profileID: 0,
    client_Name: "",
    client_LastName: "",
    companyName: "",
    type: 1,
    typeDesc: "",
    thumbnail_img: "",
    cover_img: "",
    descriptionResume: "",
    phone: "",
    email: "",
    website: "",
    country: "",
    text: "",
    featured: false,
    city: "",
    active: true,
    showThumbnail: true,
    userAdded: "",
    dateAdded: "",
    staff: false,
    profTypes: [],
    countryCode: "US",
    countryName: "",
    cityCode: "",
    cityName: "",
    stateCode: "",
    stateName: "",
    tierId: 1,
    tierName: "",
  };
  const [profileTypes, setProfileTypes] = useState<Array<ProfileType>>([]);
  const [countries, setCountries] = useState<Country[]>(locations as Country[]);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedState, setSelectedState] = useState<State | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [tiers, setTiers] = useState<Array<Tier>>([]);
  useEffect(() => {
    //load cities if state is populated
    console.log("statecode: " + props.formData.stateCode);

    getProfileTypes("asasa").then((resp) => {
      if (resp.data) setProfileTypes(resp.data);
    });

    getProfileTiers("asasa").then((resp) => {
      if (resp.data) setTiers(resp.data);
      console.log(resp.data);
    });
  }, []);

  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  const inputFileThumb = useRef(document.createElement("input"));
  const inputFileCover = useRef(document.createElement("input"));
  const [isSaving, setIsSaving] = useState(false);

  const [isHoveredProfile, setIsHoeverdProfile] = useState(false);
  const [isHoveredCover, setIsHoeverdCover] = useState(false);

  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [profileImage, setProfileImage] = useState<File | null>(null);

  const handleCheckbooks = (e: any) => {
    props.updateFormData({
      ...props.formData,
      [e.target.name]: e.target.checked,
    });
  };

  ///upload of images to aws amplify
  const handleUpload = async (id: number) => {
    const file1 = profileImage;
    const file2 = coverImage;
    const finalpath = path + id + "/";
    try {
      if (profileImage)
        await Storage.put(finalpath + profileImage.name, profileImage, {});
      if (coverImage)
        await Storage.put(finalpath + coverImage.name, coverImage, {});
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  /////////////FUNCTIONSS//////////////
  const handleCountryInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    var country = countries.find((i) => i.name === value) ?? null;
    if (country) {
      reloadStatesAndCities(country.id.toString());
      props.updateFormData({
        ...props.formData,
        ["countryName"]: country.name,
        ["countryCode"]: country.iso2.toString(),
      });
    }
  };

  const handleStateInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    var state = states.find((i) => i.name === value) ?? null;
    if (state) reloadCities(state.id.toString());
  };

  const handleStateChange = (
    event: React.ChangeEvent<{}>,
    value: State | null
  ) => {
    setSelectedState(value);

    var id = value?.id;
    var name = value?.name;

    props.updateFormData({
      ...props.formData,
      ["stateCode"]: id?.toString(),
      ["stateName"]: name,
    });

    if (id) {
      reloadCities(id.toString());
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (
    event: React.ChangeEvent<{}>,
    value: City | null
  ) => {
    setSelectedCity(value);

    var id = value?.id;
    var name = value?.name;

    props.updateFormData({
      ...props.formData,
      ["cityCode"]: id?.toString(),
      ["cityName"]: name,
    });
  };

  const reloadStatesAndCities = (value: string) => {
    const selectedCountry = countries.find(
      (country) => country.id.toString() === value
    );
    const statesData: State[] = [];

    selectedCountry?.states.forEach((state) => {
      statesData.push({
        id: state.id,
        name: state.name,
        cities: state.cities.map((city) => ({ id: city.id, name: city.name })),
      });
    });

    setStates(statesData);
  };

  const reloadCities = (value: string) => {
    const selectedState = states.find((state) => state.id.toString() === value);
    const citiesData: City[] = [];

    selectedState?.cities.forEach((city) => {
      citiesData.push({ id: city.id, name: city.name });
    });

    setCities(citiesData);
  };

  const handleCountryChange = (
    event: React.ChangeEvent<{}>,
    value: Country | null
  ) => {
    if (value) {
      setSelectedCountry(value);

      var id = value.id.toString();
      var name = value.name;
      var countryCode = value.iso2.toString();
      props.updateFormData({
        ...props.formData,
        ["countryName"]: name,
        ["countryCode"]: countryCode,
      });

      reloadStatesAndCities(id);
    } else {
      setSelectedCountry(null);
      setStates([]);
      setCities([]);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    props.formData.dateAdded = new Date();
    setIsSaving(true);
    console.log(props.formData);
    postProfile(props.formData, "sss").then((resp) => {
      console.log(resp);
      handleUpload(resp);

      props.updateFormData(defaultFormData);
      setCoverImage(null);
      setProfileImage(null);
      queryClient.refetchQueries(["Profiles"]);
      setOpen(true);
    });

    setIsSaving(false);
  };

  const handleProfileTypesChange = (event: any, value: number[]) => {
    props.updateFormData({ ...props.formData, ["profTypes"]: value });
    console.log(value);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    props.updateFormData({ ...props.formData, [name]: value });
  };

  const getFileName = (path: string) => {
    return path.replace(/^.*[\\\/]/, "");
  };

  const handleThumbnailImageClick = (e: any) => {
    if (inputFileThumb.current) inputFileThumb.current.click();
  };

  const handleCoverImageClick = (e: any) => {
    if (inputFileCover.current) inputFileCover.current.click();
  };

  const handleChangeThumbnailPict = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setProfileImage(event.target.files[0]);
      props.updateFormData({
        ...props.formData,
        ["thumbnail_img"]: event.target.files[0].name,
      });
      console.log(profileImage);
      console.log(props.formData);
    }
  };

  const handleChangeCoverPict = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setCoverImage(event.target.files[0]);
      props.updateFormData({
        ...props.formData,
        ["cover_img"]: event.target.files[0].name,
      });
      console.log(props.formData);
      console.log("cover changed");
    }
  };

  return (
    <div className="form" id="form">
      {isSaving && <CircularProgress size="4rem" className="spinner" />}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Profile Updated!!
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <div className="row formrow ">
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="client_Name"
              label="First Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              placeholder="Enter Client's First Name"
              value={props.formData.client_Name}
              required
              size="small"
            />
          </div>
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="client_LastName"
              label="Last Name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              placeholder="Enter Client's Last Name"
              required
              value={props.formData.client_LastName}
              size="small"
            />
          </div>
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="companyName"
              label="Company Name"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Company Name"
              variant="outlined"
              onChange={handleChange}
              required
              value={props.formData.companyName}
              size="small"
            />
          </div>
        </div>
        <div className="row formrow">
          <div className="col col-lg-4">
            <Autocomplete
              disablePortal
              multiple
              size="small"
              id="combo-box-demo"
              placeholder="SelectTye"
              options={profileTypes}
              openOnFocus
              value={props.formData.profTypes}
              getOptionLabel={(option) => option.typeName}
              onChange={handleProfileTypesChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Profile Type(s)"
                  name="type"
                />
              )}
              fullWidth
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.typeID}>
                    <span>{option.typeName}</span>
                  </li>
                );
              }}
            />
          </div>
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="phone"
              size="small"
              label="Phone Number"
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              type="number"
              placeholder="Enter Company's Phone Number"
              onChange={handleChange}
              value={props.formData.phone}
            />
          </div>
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="email"
              label="Email"
              required
              size="small"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Company's Email"
              variant="outlined"
              onChange={handleChange}
              value={props.formData.email}
            />
          </div>
        </div>

        <div className="row formrow">
          <div className="col col-lg-4">
            <TextField
              fullWidth
              className="formImputs"
              id="outlined-basic"
              name="descriptionResume"
              label="Biography Short"
              required
              size="small"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Company's Short Biography"
              variant="outlined"
              onChange={handleChange}
              value={props.formData.descriptionResume}
            />
          </div>
          <div className="col col-lg-4">
            <Autocomplete
              size="small"
              fullWidth
              openOnFocus
              onChange={handleCountryChange}
              onInputChange={handleCountryInputChange}
              options={countries}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company's Based Country"
                  name="country"
                />
              )}
              getOptionLabel={(option) => option.name}
              value={
                countries.find(
                  (i) => i.iso2.toString() === props.formData.countryCode
                ) ?? null
              }
            />
          </div>
          <div className="col col-lg-4">
            <Autocomplete
              size="small"
              fullWidth
              disablePortal
              openOnFocus
              onChange={handleStateChange}
              onInputChange={handleStateInputChange}
              options={states}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Company's Based State" />
              )}
              disabled={!props.formData.countryCode}
              value={
                states.find(
                  (i) => i.id.toString() === props.formData.stateCode
                ) ?? null
              }
            />
          </div>

          <div className="col col-lg-4">
            <Autocomplete
              size="small"
              fullWidth
              disablePortal
              openOnFocus
              onChange={handleCityChange}
              options={cities}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Company's Based City" />
              )}
              disabled={!props.formData.stateCode}
              value={
                cities.find(
                  (i) => i.id.toString() === props.formData.cityCode
                ) ?? null
              }
            />
          </div>

          <div className="col col-lg-4">
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              placeholder="Select Tier"
              options={tiers}
              openOnFocus
              value={
                tiers.find((i) => i.tierID === props.formData.tierID) ?? null
              }
              inputValue={
                tiers.find((i) => i.tierID === props.formData.tierID)
                  ?.tierName ?? ""
              }
              getOptionLabel={(option) => option.tierName}
              onChange={(event, newValue) => {
                props.updateFormData({
                  ...props.formData,
                  ["tierID"]: newValue?.tierID ?? 0,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Profile Tier" name="Tier" />
              )}
              fullWidth
            />
          </div>
        </div>

        <div className="row formrow">
          <div className="col col-lg-4">
            <TextField
              id="outlined-multiline-flexible"
              label="Biography"
              name="text"
              placeholder="Enter Company's Biography"
              multiline
              InputLabelProps={{ shrink: true }}
              maxRows={4}
              fullWidth
              required
              size="small"
              minRows={4}
              value={props.formData.text}
              onChange={handleChange}
            />
          </div>
          <div className="col col-lg-4">
            <div className="row formrow">
              <div className="col col-lg-16">
                <TextField
                  id="input-with-icon-textfield"
                  label="Cover Image"
                  size="small"
                  name="cover_img"
                  value={getFileName(props.formData.cover_img)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddAPhoto />
                      </InputAdornment>
                    ),
                  }}
                  onClick={handleCoverImageClick}
                  disabled
                  onMouseEnter={() => setIsHoeverdCover(true)}
                  onMouseLeave={() => setIsHoeverdCover(false)}
                  variant="outlined"
                  fullWidth
                ></TextField>
                <input
                  hidden
                  ref={inputFileCover}
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleChangeCoverPict}
                />

                {isHoveredCover && (
                  <dialog
                    className="dialog"
                    style={{ position: "absolute", zIndex: 9999 }}
                    open
                  >
                    <img
                      className="image"
                      src={
                        props.formData.cover_img != ""
                          ? props.formData.cover_img
                          : coverImage != null
                          ? URL.createObjectURL(coverImage)
                          : ""
                      }
                      alt="no image"
                    />
                  </dialog>
                )}
              </div>
            </div>

            <div className="row formrow">
              <div className="col col-lg-16">
                <TextField
                  id="input-with-icon-textfield"
                  label="Thumbnail Image"
                  size="small"
                  name="thumbnail_img"
                  value={getFileName(props.formData.thumbnail_img)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddAPhoto />
                      </InputAdornment>
                    ),
                  }}
                  onClick={handleThumbnailImageClick}
                  disabled
                  onMouseEnter={() => setIsHoeverdProfile(true)}
                  onMouseLeave={() => setIsHoeverdProfile(false)}
                  variant="outlined"
                  fullWidth
                ></TextField>
                <input
                  hidden
                  ref={inputFileThumb}
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleChangeThumbnailPict}
                />

                {isHoveredProfile && (
                  <dialog
                    className="dialog"
                    style={{ position: "absolute", zIndex: 9999 }}
                    open
                  >
                    <img
                      className="image"
                      src={
                        props.formData.thumbnail_img != ""
                          ? props.formData.thumbnail_img
                          : profileImage != null
                          ? URL.createObjectURL(profileImage)
                          : ""
                      }
                      alt="no image"
                    />
                  </dialog>
                )}
              </div>
            </div>
          </div>
          <div className="col col-lg-4">
            <div className="row formrow">
              <div className="col col-lg-16">
                <TextField
                  fullWidth
                  className="formImputs"
                  id="outlined-basic"
                  name="website"
                  label="Website"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  placeholder="Enter Company's Website"
                  variant="outlined"
                  onChange={handleChange}
                  value={props.formData.website}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row lastrow">
          <div className="col col-lg-4   me-auto">
            <div className="col btnsubmit">
              <Button
                type="submit"
                sx={{ width: 200, padding: 1 }}
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="col col-lg-1">
            <FormControlLabel
              control={
                <Checkbox
                  name="featured"
                  value={props.formData.featured}
                  checked={props.formData.featured}
                  onChange={(e) => handleCheckbooks(e)}
                />
              }
              label="FEAT"
            />
          </div>
          <div className="col col-lg-1">
            <FormControlLabel
              control={
                <Checkbox
                  name="active"
                  checked={props.formData.active}
                  value={props.formData.active}
                  onChange={(e) => handleCheckbooks(e)}
                />
              }
              label="ACTI"
            />
          </div>
          <div className="col col-lg-1">
            <FormControlLabel
              control={
                <Checkbox
                  name="showThumbnail"
                  checked={props.formData.showThumbnail}
                  value={props.formData.showThumbnail}
                  onChange={(e) => handleCheckbooks(e)}
                />
              }
              label="SHOW"
            />
          </div>
          <div className="col col-lg-1">
            <FormControlLabel
              control={
                <Checkbox
                  name="staff"
                  checked={props.formData.staff}
                  value={props.formData.staff}
                  onChange={(e) => handleCheckbooks(e)}
                />
              }
              label="TEAM"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
