import { AuthenticationDetails, ChallengeName, CognitoUser } from "amazon-cognito-identity-js";
import { useState } from "react";
import { useCookies } from "react-cookie";

import UserPool from "./userPool";

export default function useAuth() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
    const [data, setData] = useState<any>(null);
    const [cookies, setCookie] = useCookies(['userId', 'userIdJWETToken', 'email']);
    const [currentScreen, setCurrentScreen] = useState('MAIN');

    const authenticate = (email: string, password: string, callbacks: any = null) => {
        setCookie('email', email);
        setIsProcessing(true);
        setIsError(false);
        setData(false);

        const cu = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        setCognitoUser(cu);
        cu.authenticateUser(new AuthenticationDetails({
            Username: email,
            Password: password
        }), {
            mfaRequired: (challengeName: ChallengeName, challengeParameters: any) => {
                console.log('mfaRequired');
                console.log('challengeName = ', challengeName);
                console.log('challengeParameters = ', challengeParameters);
                setCurrentScreen('MFA_REQUIRED');
                // setCookie('confirm_type', 'MFA');
                // setCookie('cognitoUser', cu);
                // router.push('confirm-code');
            },
            onSuccess: (data: any) => {
                console.log('onSuccess = ', data);
                
                const jwtToken = data.idToken.jwtToken;
                const userId = data.accessToken.payload.username;
                setCookie('userId', userId);
                setCookie('userIdJWETToken', jwtToken);
                setIsProcessing(false);
                setIsAuthenticated(true);
                setData(data);
                if (callbacks && callbacks.onSuccess){
                    callbacks.onSuccess(data);
                }
            },
            onFailure: (err) => {
                console.log('onFailure = ', err);
                setIsProcessing(false);
                setIsError(true);
                setData(err.message);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                
            },
        });
    }

    return { 
        isProcessing, 
        isAuthenticated, 
        isError,
        currentScreen,
        cognitoUser, 
        data, 
        authenticate 
    };
}