import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Login from "./Components/LoginComponent/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import home from "./Components/Home/Home";
import Home from "./Components/Home/Home";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <div>
            <QueryClientProvider client={queryClient}>

    <BrowserRouter>
      <Routes>
                
        <Route index element={<Login />} />
                
        <Route path="login" element={<Login />} />
                
        <Route path="Home" element={<Home />} />
              
      </Routes>
    </BrowserRouter>
    </QueryClientProvider>
  </div>
);
