const awsExports = {
  // Auth: {
  //   identityPoolId: 'us-east-1:c9d456cf-5b03-4147-a43b-d425c906efb9',
  //   region: 'us-east-1',
  //   userPoolId: 'us-east-1_EuUclH4aQ',
  //   userPoolWebClientId: '2377hm84d4bomhfs2bf6fdri4c',
  // },
  Auth: {
    identityPoolId: "us-east-2:1bf1f7ca-06e1-4477-9668-c1d07134bcbe",
    region: "us-east-2",
    userPoolId: "us-east-2_lKtSsV5dY",
    userPoolWebClientId: "5glmtb920im4f5c2q0nccie2j",
  },
  Storage: {
    AWSS3: {
      bucket: "api.thelocalproject",
      region: "us-east-1",
      maximumFileSize: 50000000, // 50MB in bytes
    }
  },
};

export default awsExports;
