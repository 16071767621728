import { Checkbox, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Article, Staff } from "../Models/Models";
import { getArticles } from "../Api/Api";
import { red } from "@mui/material/colors";

export default function ArticlesTable(props: {
  updateFormData: (formData: any) => void;
}) {
  ////declarations

  useEffect(() => {
    if (searchQuery === "") setFilteredData(data);
  });
  const queryClient = useQueryClient();
  const { data, status, refetch } = useQuery<Array<Article>>(["Articles"], () =>
    getArticles("asasa")
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [articles, setArticles] = useState<Array<Article>>([]);

  const fetchArticles = async (): Promise<Article[]> => {
    return getArticles("asasa").then((resp) => {
      if (resp.data) {
        console.log(resp.data);
        setArticles(resp.data);
        setFilteredData(resp.data);
        return resp.data;
      }
    });
  };

  const columns = [
    {
      field: "TypeDesc",
      headerName: "",
      width: 50,
      renderCell: (params: any) => {
        return (
          <IconButton
            aria-label="Edit"
            color="primary"
            onClick={(e) => onEditClick(e, params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    { field: "articleID", headerName: "ID", width: 50 },
    { field: "articleTypeName", headerName: "Type", flex: 1 },
    { field: "serieName", headerName: "Serie", flex: 1 },
    { field: "title", headerName: "Title", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "countryName", headerName: "Country", flex: 1 },
    { field: "cityName", headerName: "City", flex: 1 },
    {
      field: "featured",
      headerName: "Featured",
      flex: 1,
      renderCell: (params: any) => {
        return <Checkbox checked={params.row.featured}></Checkbox>;
      },
    },

    {
      field: "active",
      headerName: "Active",
      flex: 1,
      renderCell: (params: any) => {
        return <Checkbox checked={params.row.active}></Checkbox>;
      },
    },
  ];
  /////functionnsss
  const onEditClick = (e: any, row: any) => {
    e.stopPropagation();
    // setClickedRow(row);
    console.log(row);
    props.updateFormData(row);
  };
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setFilteredData(
      data &&
        data.filter((d) =>
          Object.values(d).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(query.toLowerCase())
          )
        )
    );
  };

  return (
    <div style={{ height: 850, width: "100%" }}>
      <input
        className="searchInput"
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
      />

      <DataGrid
        rows={filteredData ?? []}
        columns={columns}
        //  pageSize={10}
        getRowId={(row) => row.articleID}
        //  rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        rowHeight={25}
        autoPageSize
        sx={{
          boxShadow: 2,
          border: 2,

          "& .profile--false": {
            bgcolor: red,
          },
        }}
        getRowClassName={(params) => `profile--${params.row.active}`}
      />
    </div>
  );
}
