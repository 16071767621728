import { Checkbox, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Staff } from "../Models/Models";
import { getWriters } from "../Api/Api";
import { red } from "@mui/material/colors";

export default function WritersTable(props: {
  updateFormData: (formData: any) => void;
}) {
  const queryClient = useQueryClient();

  //DECLARATIONS//////////
  useEffect(() => {
    if (searchQuery === "") setFilteredData(data);
  });
  const { data, status, refetch } = useQuery<Array<Staff>>(["Writers"], () =>
    getWriters("asasa")
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [writers, setWriters] = useState<Array<Staff>>([]);

  const fetchWriters = async (): Promise<Staff[]> => {
    return getWriters("asasa").then((resp) => {
      if (resp.data) {
        console.log(resp.data);
        setWriters(resp.data);
        setFilteredData(resp.data);
        return resp.data;
      }
    });
  };

  const columns = [
    {
      field: "TypeDesc",
      headerName: "",
      width: 50,
      renderCell: (params: any) => {
        return (
          <IconButton
            aria-label="Edit"
            color="primary"
            onClick={(e) => onEditClick(e, params.row)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    { field: "staffID", headerName: "ID", width: 50 },
    { field: "firstName", headerName: "First name", flex: 1 },
    { field: "lastName", headerName: "Last name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "webSite", headerName: "Web", flex: 1 },
    { field: "country", headerName: "Country", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      flex: 1,
      renderCell: (params: any) => {
        return <Checkbox checked={params.row.active}></Checkbox>;
      },
    },
  ];

  ///FUNCTIONS///////////

  const onEditClick = (e: any, row: any) => {
    e.stopPropagation();
    // setClickedRow(row);
    console.log(row);
    props.updateFormData(row);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setFilteredData(
      data &&
        data.filter((d) =>
          Object.values(d).some((value) =>
            value.toString().toLowerCase().includes(query.toLowerCase())
          )
        )
    );
  };

  return (
    <div style={{ height: 450, width: "100%" }}>
      <input
        className="searchInput"
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
      />

      <DataGrid
        rows={filteredData ?? []}
        columns={columns}
        pageSize={10}
        getRowId={(row) => row.staffID}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        rowHeight={25}
        sx={{
          boxShadow: 2,
          border: 2,

          "& .profile--false": {
            bgcolor: red,
          },
        }}
        getRowClassName={(params) => `profile--${params.row.active}`}
      />
    </div>
  );
}
