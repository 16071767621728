import React, { useState } from "react";
import "./navbar.css";
import assets from "../../Assets";
interface NavbarProps {
  activePage: string;
  setActivePage: (activePage: string) => void;
}

export default function Navbar(props: NavbarProps) {
  return (
    <div className="navbar-container">
      <img
        className="navbar-icon"
        src={require("../../Assets/Logotype-Small-White.png")}
        alt="logo"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      <hr className="custom-hr"/>
      <div className="navigation-container container ">
        <button
          className={
            props.activePage === "magazine"
              ? "btn btn-primary  mybutton-active"
              : "btn btn-primary  mybutton"
          }
          //   className="btn btn-primary mb-2 mybutton"
          onClick={() => props.setActivePage("magazine")}
        >
          Magazines
        </button>
   
        <button
          className={
            props.activePage === "articles"
              ? "btn btn-primary  mybutton-active"
              : "btn btn-primary  mybutton"
          }
          //   className="btn btn-primary  mybutton"
          onClick={() => props.setActivePage("articles")}
        >
          Articles
        </button>
        <button
          className={
            props.activePage === "profiles"
              ? "btn btn-primary  mybutton-active"
              : "btn btn-primary  mybutton"
          }
          //   className="btn btn-primary  mybutton"
          onClick={() => props.setActivePage("profiles")}
        >
          Profiles
        </button>
        <button
          className={
            props.activePage === "writers"
              ? "btn btn-primary  mybutton-active"
              : "btn btn-primary  mybutton"
          }
          //   className="btn btn-primary  mybutton"
          onClick={() => props.setActivePage("writers")}
        >
          Staff
        </button>
      </div>
    </div>
  );
}
